<template>
  <div>
    <!-- 点评病例 -->
    <div class="video_box_outer">
      <!-- 一条卡片信息 start-->
      <div class="video_box">
          <div class="img_box">
              <van-image width="135" height="82" src="https://img01.yzcdn.cn/vant/cat.jpeg"/>
          </div>
          <div class="detail_box">
              <div class="detail">
                  <span class="detail_title">【病例分享】肺癌科研1号</span>
                  <span class="detail_title">点击查看病例详情</span>
                  <!-- <span class="detail_status"><van-icon name="checked" />审核成功</span> -->
              </div>
              <!-- <van-icon class="detail_icon" name="ellipsis" /> -->
              <div class="btn_box">
                <van-button type="default" class="detail_icon comment_btn" @click="Comment()">待点评</van-button>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    Comment(){
      // console.log("待点评");
      this.$router.push({
        path:"comment_page"
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video_box_outer{
  .video_box{
      // background: #ccc;
      margin: 15px;
      display: flex;
      // align-items: center;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
      .img_box{
          display: flex;
          padding: 9px;
      }
      .detail_box{
          display: flex;
          // background: #ccc;
          width: 100%;
          justify-content: space-between;
          .detail{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-evenly;
              .detail_title{
                  font-size: 14px;
                  color: #101010;
              }
              .detail_date{
                  font-size: 12px;
                  color: #101010;
              }
              .detail_status{
                  font-size: 12px;
                  color: #15DB4E;
                  display: flex;
                  align-items: center;
              }
          }
          .btn_box{
            // background: #ccc;
            // align-items: center;
            display: flex;
            align-items: center;
            .detail_icon{
                margin-right: 5px;
                
            }
            .comment_btn{
              display: flex;
              padding: 0;
              height: 30px;
              width: 60px;
              justify-content: center;
              align-items: center;
              font-size: 13px;
            }
          }
          
      }
  }
}
</style>